import styled from 'styled-components';
import LinkArrowImage from '../../images/icon-home-arrow-purple.png';

export const MainDiv = styled.div`
  color: #666;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  width: 740px;
  margin: auto;
  padding: 40px 0 10px 0;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
    padding: 30px;
  }
  @media (max-width: 991px) {
    width: 100%;
    padding: 30px;
  }
`;
export const HeaderH1 = styled.h1`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Figtree-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #4d1367;
  font-size: 45px;
  line-height: 50px;
  letter-spacing: 0.02em;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 40px;
  }
`;
export const HeaderH2 = styled.h2`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Figtree-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #733695;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.02em;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 30px 0;
  padding: 0;
  text-align: left;
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
    font-weight: bold;
  }
  code {
    font-size: 19px;
    line-height: 28px;
    color: #666;
    font-family: 'Figtree-Regular', arial, sans-serif;
    letter-spacing: 0.02em;
    font-style: normal !important;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
`;

export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Figtree-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #ac85d9;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 0.02em;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;

export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;

export const ListItem = styled.li`
  margin: 0;
  padding: 4px 0 4px 25px;
  list-style: none;
  background: url(${LinkArrowImage}) no-repeat 0 10px;
  background-size: 7px auto;
  p {
    margin: 0;
  }
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #c77dec;
  :hover {
    text-decoration: none;
    color: #4d1367;
    outline: none;
  }
`;
export const ImageStyle = styled.img`
  float: right;
  height: auto;
  width: auto;
  border: 0;
  vertical-align: top;
  margin: ${(props) => (props.$hasMargin === true ? '0 0 30px 30px' : '0')};
  @media (max-width: 767px) {
    display: block;
    float: none;
    width: 100%;
    height: auto;
    margin: 20px 0px 20px 0px;
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;
